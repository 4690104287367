.container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 20px 0px;
  box-sizing: border-box;
}

.new_account_btn {
  min-width: 115px;
}

@media all and (max-width: 900px) {
  .new_account_btn {
    display: none;
  }
  .container {
    justify-content: flex-start;
  }
}
