.upper_container {
  box-sizing: border-box;
  background-color: lightgray;
  padding: 50px 100px;
  display: flex;
  justify-content: space-between;
  margin: 0px;
  position: relative;
}

.logo_container {
  display: flex;
  flex-direction: column;
  margin: 0px;
  margin-right: 10vw;
}

@media all and (max-width: 1300px) {
  .logo_container {
    margin-right: 5vw;
  }
}
@media all and (max-width: 1100px) {
  .logo_container {
    margin-right: 0;
  }
}

.logo {
  margin: 0px 0px 20px -8px;
  width: 150px;
}

.slogan {
  margin-top: 20px;
  margin-right: 20px;
  width: 170px;
}

.whatsapp {
  display: flex;
}

.whatsapp__img {
  margin-top: 150px;
  height: 50px;
}

.list_container {
  width: 25vw;
}

.list {
  margin-top: 0px;
  margin-left: 90px;
  padding: 0;
  padding-bottom: 30px;
  margin-bottom: 30px;
}

.list__title {
  margin: 0px;
  font-weight: 700;
  padding-bottom: 10px;
  padding-top: 0px;
  color: rgb(37, 37, 37);
}

.list__title:hover {
  cursor: pointer;
}

.list__item {
  list-style-type: none;
  margin: 15px 0px;
  color: rgb(60, 60, 60);
}

.category_partners_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0;
  padding: 0;
}

@media all and (max-width: 900px) {
  .category_partners_container {
    display: flex;
    flex-direction: column;
    padding-right: 5%;
  }

  .about_contact_container {
    margin-bottom: 200px;
  }
}

.contact {
  padding-left: 10px;
}

.about {
  margin-bottom: 30px;
  padding-left: 10px;
}

.legal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: gray;
  margin: 0px;
  padding: 20px 100px;
}

@media all and (max-width: 600px) {
  .legal {
    padding: 20px;
    max-width: 100%;
    align-items: center;
  }

  .about {
    padding-left: 30px;
  }
}

.copyright {
  width: 25%;
  min-width: 150px;
  margin: auto 0;
}

.terms_privacy_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.terms,
.privacy {
  margin: 0px;
  margin-left: 50px;
}

.terms_privacy_container_mobile {
  display: none;
}

@media only screen and (max-width: 750px) {
  .upper_container {
    flex-direction: column;
    padding: 10px 20px;
  }

  .list_container {
    width: auto;
  }

  .list {
    width: 100px;
    margin: 30px 60px 60px 60px;
    padding: 0;
  }

  .logo_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px 40px 20px;
    margin: 20px 0 30px 0;
  }

  .slogan {
    display: none;
  }

  .category_partners_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0;
    padding: 0;
  }

  .about_contact_container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
  }

  .logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    width: 150px;
  }

  .logo__img {
    width: 150px;
  }

  .whatsapp {
    padding: 0px;
    margin: 0px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .whatsapp__img {
    margin: 0px;
  }

  .terms_privacy_container {
    display: none;
  }

  .terms_privacy_container_mobile {
    display: flex;
    text-align: right;
  }

  .terms_privacy_mobile {
    margin: 0;
  }
}

@media all and (max-width: 500px) {
  .list {
    margin: 10px 30px 30px 30px;
  }
}
