.mainPageContainer {
	min-height: calc(100vh - 64px);
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
}

.categories_container {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0;
	margin: 0;
	width: 100%;
}

@media all and (max-width: 600px) {
	.categories_container {
		margin: 0 0 50px 0;
	}
}
