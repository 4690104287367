.container {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 50px;
	padding: 0;
	width: 100%;
	max-width: 60em;
}

.grid {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
}

.single {
	margin: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: white;
	border-radius: 5px;
	padding: 20px;
	box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.single:hover {
	cursor: pointer;
	box-shadow: rgba(75, 79, 83, 0.2) 0px 16px 48px;
	transform: scale(1.05);
	text-decoration: none;
}

.info {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.image {
	width: 80px;
}

.title {
	width: 140px;
	height: 48px;
	font-weight: 600;
	display: flex;
	justify-content: center;
	text-align: center;
	align-items: center;
}

@media all and (max-width: 1024px) {
	.grid {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 1fr 1fr;
	}
}

@media only screen and (max-width: 600px) {
	.container {
		margin: 0px;
		width: 100vw;
	}

	.single {
		width: 150px;
		height: auto;
		margin: 10px;
	}

	.title {
		font-size: 1rem;
		width: 120px;
		height: 100px;
	}
}
