.container {
  display: none;
}

@media only screen and (max-width: 600px) {
  .container {
    display: flex;
    position: relative;
    margin-bottom: 56.62px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    padding: 10px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    background-color: white;
  }
  .logo {
    width: 100px;
  }

  .icons {
    font-size: 1.5rem;
  }
}
