.header {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 10px 8vw;
}

.logo {
  display: flex;
  width: 120px;
  padding: 0px;
}

.logo_img {
  width: 120px;
  padding: 0px;
}

@media all and (max-width: 600px) {
  .header {
    display: none;
  }
}
