.container {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.image {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.slogan {
  position: absolute;
  left: 10%;
  bottom: 10%;
  font-size: 3rem;
  z-index: 999;
  width: 80vw;
  color: lightgray;
}

@media all and (max-width: 900px) {
  .slogan {
    font-size: 2.4rem;
  }
}

@media all and (max-width: 600px) {
  .slogan {
    font-size: 2rem;
    width: 300px;
  }
}
