.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
}

.search_container {
	display: flex;
	justify-content: center;
	width: 100%;
	background-color: gray;
	border: 1px solid rgb(83, 83, 83);
}

.search {
	position: absolute;
	z-index: 999;
	top: 300px;
	left: 10%;
	opacity: 0.4;
	font-size: 1.2rem;
	width: 80%;
	background-color: white;
	justify-content: space-between;
	border-radius: 5px;
}

.search_input {
	padding: 3px 8px;
	width: 90%;
	border: none;
	color: black;
	opacity: 1;
	background-color: transparent;
}

.image_container {
	height: 400px;
	max-width: 100vw;
	overflow: hidden;
}

.image {
	transform: translateX(-20px);
}

.slogan {
	margin: 20px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.slogan__title {
	font-weight: 600;
	color: #591b5a;
}
