@import "../../styles/variables.scss";

.mainContainer {
  padding: 8px 18px;
  margin: 10px;
  width: 40vw;
  height: auto;
  align-items: center;
  border-radius: 7px;
  display: flex;
  justify-content: space-between;
  min-width: 400px;
  max-width: 500px;
}

.boxHeader {
  padding: 0;
  width: 500px;
}
@media all and (max-width: 1050px) {
  .boxHeader {
    width: 50vw;
  }
}

.boxLanding {
  box-shadow: $box-shadow-default;
}

.input_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 80px;
  width: 100%;
}
@media only screen and (max-width: 600px) {
  .input_container {
    width: 100%;
    flex-direction: column;
  }
}
.input_container_header {
  height: 41px;
}

.singleInput {
  padding-right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.inputTitle {
  font-weight: 500;
  margin-bottom: 0;
  font-size: 1.1rem;
}

.input {
  width: auto;
  border: none;
  margin: 0px;
  font-size: 1rem;
  outline: none;
  padding: 10px 0 5px 0;
}

.searchBtn {
  border-radius: 5px;
  background: linear-gradient(135deg, #f28f5f 0%, rgba(255, 255, 255, 0) 100%), #f76e2b;
  padding: 15px;
  display: flex;
  border: none;
  align-items: center;
}

.searchBtn:hover {
  background-color: $color-primary-hover;
}

.searchBtnHeader {
  background-color: transparent;
  border: none;
  padding: 10px;
  display: flex;
  border: none;
  align-items: center;
}
.searchIconHeader {
  color: gray;
  align-items: center;
  justify-content: center;
}

.buscar {
  margin: 3px;
  color: white;
}

.searchBtn:hover {
  cursor: pointer;
}

.searchIcon {
  color: white;
  align-items: center;
  justify-content: center;
}

@media all and (max-width: 600px) {
  .mainContainer {
    width: 100%;
    flex-direction: column;
    padding: 20px;
    margin: 0px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    min-width: 90vw;
    min-height: 200px;
  }
}

@media only screen and (max-width: 600px) {
  .tempSearchBar {
    height: 10vh;
    width: 90vw;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    margin: 0;
    transform: translateY(-35%);
  }

  .input_container {
    margin: 0;
    padding: 0;
    height: auto;
  }

  .searchBtn {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    padding: 10px 82px;
    color: white;
    border: none;
    width: 100%;
    font-size: 1.2rem;
  }

  .search-bar-button-text {
    font-size: 1.8rem;
    transform: translateY(-1px);
  }
}

@media all and (max-width: 600px) {
  .search-bar-inputs {
    font-size: 1.4rem;
  }
}

/* for both */

.tempSearchBar {
  margin: 0;
  position: relative;
}

.alert {
  position: absolute;
  top: 115%;
  left: 20%;
}
