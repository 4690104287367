.userInfo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.userAvatar {
  height: 40px;
  width: 40px;
  overflow: hidden;
  border-radius: 40px;
  margin-left: 20px;
  background-color: #591b5a;
  background: linear-gradient(135deg, #7c347e 0%, rgba(255, 255, 255, 0) 100%), #591b5a;
  display: flex;
  justify-content: center;
  align-items: center;
}

.background {
  display: flex;
  justify-content: center;
  align-items: center;
}

.initials {
  color: #f76e2a;
  font-weight: 500;
  background: none;
  margin: 0;
}

@media all and (max-width: 1050px) {
  .userFavorites {
    display: none;
  }
}
