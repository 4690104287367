@import url("https://fonts.googleapis.com/css?family=Ubuntu");
@import "styles/variables";
@import "antd/dist/antd.css";

.body {
  min-height: 100vh;
  width: 100vw;
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  font-family: "Ubuntu";
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  text-decoration: none;
}

li,
ul {
  padding: 0;
  margin: 0;
  list-style: none;
  text-decoration: none;
}

.section {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.btn {
  border-radius: 5px;
  padding: 7.5px 15px;
  border: 0px;
  font-size: 1rem;
  min-width: fit-content;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.btn:hover {
  cursor: pointer;
}

.btn--secondary {
  margin-right: 10px;
  color: gray;
  border: 1px gray solid;
}

.btn--primary {
  color: white;
  background-color: #414141;
  border: 1px #414141 solid;
  min-width: 100%;
}

.logo-slogan-searchbar {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
  padding-top: 0px;
  box-sizing: border-box;
}

.logo {
  font-size: 32px;
  font-weight: 700;
  padding: 20px;
  padding-bottom: 0px;
}
@media only screen and (max-width: 600px) {
  .logo {
    display: none;
  }
}

.logo img {
  width: 300px;
}

.slogan {
  margin-bottom: 40px;
  margin-top: 0px;
  color: #2c2c2c;
  opacity: 0.75;
}
@media only screen and (max-width: 600px) {
  .slogan {
    display: none;
  }
}

.search-bar {
  padding: 8px 12px;
  margin: 10px;
  width: auto;
  align-items: center;
  border-radius: 7px;
  display: flex;
  justify-content: space-between;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

@media only screen and (max-width: 600px) {
  .search-bar {
    width: 100%;
    flex-direction: column;
    padding: 20px;
    margin: 0px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
}

.menu--mobile {
  display: none;
}

@media only screen and (max-width: 600px) {
  .search-icon {
    margin: 0 10px 0 0;
    font-size: 1.8rem;
  }

  .popup--mobile {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 20px;
    padding-top: 0;
    position: absolute;
    left: 0;
    top: initial;
    height: 110vh;
    width: 100vw;
    z-index: 999999;
  }

  .popup--mobile-inner {
    background-color: rgba(255, 255, 255, 1);
    position: relative;
  }

  .popup-instructor-name {
    font-weight: 600;
  }

  .menu--mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 0;
    position: absolute;
    top: 50px;
    left: 0;
    background-color: white;
    width: 100vw;
    height: 40vh;
    z-index: 9999;
  }

  .menu--mobile ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .menu--mobile li {
    list-style-type: none;
    padding: 10px;
    font-size: 1.4rem;
  }
}

.text--secondary {
  font-size: 12px;
  margin-top: 10px;
}

.category-subcategory {
  display: flex;
  align-items: center;
}

.back-and-save {
  display: flex;
  justify-content: space-between;
  margin: 0px;
  padding: 0px;
}

.back-and-save p {
  padding: 0px;
  margin: 0px;
}

.back {
  display: flex;
  align-items: center;
}

.back p {
  margin-left: 5px;
}

.back:hover {
  cursor: pointer;
}

.profile-welcome {
  padding-bottom: 50px;
}

.section--partners,
.section--terms {
  padding: 50px 100px;
  min-height: auto;
}

.section--terms {
  align-items: center;
  min-width: 100vw;
}

.section--login {
  padding: 10px 100px 40px 100px;
  min-height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 420px;
  padding: 20px;
  position: relative;
}

.login-input-container h2 {
  color: #591b5a;
  padding: 20px 20px;
  margin: 0px;
  width: 100%;
  box-sizing: border-box;
}

.login-input-container form {
  width: 100%;
}

.login-input-container label {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.login-input {
  max-width: 100%;
}

.login-input-container input:focus {
  color: #414141;
}

.login-close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  display: none;
}

@media all and (max-width: 600px) {
  .login-close-icon {
    display: flex;
  }
}

.login-input-container p {
  font-size: 0.9rem;
  font-weight: 500;
  padding: 0px;
  margin: 4px;
  margin-bottom: 0px;
}

.agree-on-click,
.forgot-password {
  font-size: 0.8rem;
  font-weight: 300;
  padding: 10px;
  margin: 0 20px;
  color: rgb(172, 172, 172);
  text-align: center;
}

@media all and (max-width: 600px) {
  .section--partners,
  .section--terms,
  .section--login {
    padding: 20px;
    min-height: 90vh;
    align-items: center;
    justify-content: center;
  }

  .login-input-container {
    padding: 5px;
    width: 100%;
  }

  .login-input {
    margin: 0;
    padding: 10px;
    width: 100%;
  }

  .login-input-container form {
    width: 100%;
    padding: 20px;
  }

  .agree-on-click {
    width: 100%;
    margin: 0;
  }

  .profile-container {
    margin: 0;
    padding: 0 20px 20px 20px;
  }
}

.forgot-password {
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.btn--login {
  width: 100%;
  margin-top: 20px;
}

.invisible--desktop {
  display: none;
}

.input-mode-presencial {
  display: flex;
  align-items: center;
  margin: 2px;
  color: #929292;
  width: 100%;
  width: auto;
}

.input-mode-presencial form {
  display: flex;
}

.input-mode-presencial input {
  margin: 0 5px;
  align-items: center;
  width: 60px;
  padding: 5px;
  border: 1px solid rgb(204, 203, 203);
  border-radius: 4px;
}

.input-mode-presencial input .input-state {
  width: 30px;
}

@media all and (max-width: 600px) {
  .invisible--desktop {
    display: flex;
  }

  .invisible--mobile {
    display: none;
  }

  .visible--mobile {
    display: flex;
  }

  .menu--mobile ul {
    padding: 0;
  }

  .input-mode-presencial .input-city {
    width: 80px;
  }

  .input-mode-presencial .input-state {
    width: 40px;
  }
}
