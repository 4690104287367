.container {
  align-items: center;
  padding-top: 8px;
}

.input {
  border: none;
  margin: 0px;
  font-size: 1rem;
  outline: none;
  padding: 5px 10px;
  position: relative;
  min-width: 300px;
}

.inputHeader {
  padding: 0;
  margin-left: 10px;
  width: 100%;
  min-width: 400px;
  font-size: 1rem;
}

.list {
  position: absolute;
  top: 65px;
  left: 0px;
  border-radius: 7px;
  padding: 5px;
  margin: 5px 10px 0 0;
  background-color: rgb(243, 243, 243);
  min-width: 425px;
  max-height: 300px;
  overflow: hidden;
  z-index: 99999;
}

.long_list {
  overflow: scroll;
}

.list_header {
  top: 50px;
  width: 400px;
}

@media all and (max-width: 1050px) {
  .list_header {
    width: 20vw;
  }
  .inputHeader {
    width: 40vw;
    min-width: 10vw;
  }
}

@media all and (max-width: 600px) {
  .list {
    min-width: 300px;
    max-width: 325px;
    width: 315px;
  }
}

.item {
  font-size: 1rem;
  padding: 3px;
  border-radius: 7px;
}

.title {
  font-weight: 600;
}

.title,
.item,
.category,
.subcategory {
  margin: 0;
}

.subject {
  display: flex;
  font-size: 0.8rem;
  margin: 0;
}

.margin {
  margin: 0 5px;
}

.categorySuggestion {
  font-size: 0.8rem;
}

.item:hover {
  background-color: #f76e2a;
  cursor: pointer;
  overflow: hidden;
  padding: 10px;
  color: white;
}
